<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6">
          <b-form-select
              class="text-center w-75"
              v-model="filters.category"
              :options="categories"
              text-field="name"
              @change="fetchList"
              value-field="id">
          </b-form-select>
        </b-col>
        <b-col md="1"></b-col>
        <b-col md="5">
          <div class="input-group w-100 d-flex justify-content-end">
            <input
                id="example-search-input"
                class="form-control py-2 border-right-0 border w-75"
                type="search"
                :placeholder="$t('Search')"
                size="sm"
                @input="getSearch"
                v-model="filters.search"
            >
            <span class="input-group-append">
                <div class="input-group-text bg-transparent">
                  <i><b-icon icon="search" /></i>
                </div>
              </span>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div class="mt-2">
    <SecondUserTable :documents="datas" />
      <div class="overflow-auto text-center">
        <b-pagination
            v-model="pagination.page"
            align="center"
            :total-rows="pagination.total"
            :per-page="10"
            first-number
            size="lg"
            class="pt-2"
            @page-click="getPaginationClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SecondUserTable from '@/views/loginpages/user/components/SecondUserTable'
import { getCategories, getMyParagraphs } from '@/api/documents'
import { listTemplate } from '@/utils/mixins/listTemplate'

const actions = {
  get: getMyParagraphs,
}

export default {
  name: 'SecondUserHome',
  components: { SecondUserTable },
  mixins: [
      listTemplate,
  ],
  data() {
    return {
      selected: null,
      actions,
      options: [
        { value: null, text: 'Category' },
        { value: 'a', text: 'UP' },
        { value: 'd', text: 'PP' }
      ],
      categories: [],
      filters: {
        search: '',
        category: null
      }
    }
  },
  created() {
    getCategories().then(res => {
      this.categories = res.data.data;
      this.categories.unshift({id:null,name: this.$t('All')})
    })
  },
  computed: {
    loading() {
      return this.$store.state.settings.loading
    },
  },
  methods: {
    fetchDocuments() {
      getMyParagraphs(this.filters).then(res => {
        this.documents = res.data.data
      })
    },
    getSearch() {
      setTimeout(() => {
        this.pagination.page = 1;
        this.fetchList();
      },800)
    },
    getSearchDocument() {
      this.fetchDocuments()
    },
    getPaginationClick(id) {
      this.pagination.page = id.vueTarget.value
      setTimeout(() => {
        this.fetchList();
      },10);
    }
  }
}
</script>

<style scoped>

</style>
