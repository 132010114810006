var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_vm._l((_vm.documents),function(doc,index){return _c('div',{key:index},[_c('b-card',{staticClass:"w-100 text-center mx-auto",attrs:{"size":"sm"}},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(doc.number))]),_c('p',[_vm._v(_vm._s(doc.name))]),(doc.paragraphs.length)?_c('b-table',{attrs:{"hover":"","items":doc.paragraphs,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(paragraph)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paragraph)+" ")]}},{key:"cell(deadline)",fn:function(ref){
var item = ref.item;
return [_c('b-alert',{attrs:{"variant":"info","show":""}},[(item.active_history)?_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")((item.active_history ? item.active_history.deadline : '')))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)]):_vm._e()])]}},{key:"cell(author)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_history ? item.active_history.author.lastname : '')+" "+_vm._s(item.active_history ? item.active_history.author.firstname : '')+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handlePageChange(item)}}},[_c('b-icon',{attrs:{"icon":"eye"}})],1)]}}],null,true)}):_vm._e()],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }