<template>
  <section>
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
<div v-for="(doc, index) in documents" :key="index">
  <b-card size="sm" class="w-100 text-center mx-auto">
    <h2 class="text-center">{{doc.number}}</h2>
    <p>{{doc.name}}</p>
    <b-table
        v-if="doc.paragraphs.length"
        hover
        :items="doc.paragraphs"
        :fields="fields"
        responsive=""
    >
      <template #cell(id)="data">
        <span class="text-md text-primary"> {{ (data.index +1) }}</span>
      </template>
      <template #cell(paragraph)="{item}">
        {{item.paragraph}}
      </template>
      <template #cell(deadline)="{item}" >
        <b-alert variant="info" show>
            <span v-if="item.active_history" class="d-flex justify-content-center">
            <span>
              {{ (item.active_history ? item.active_history.deadline : '') | dateFormat }}
            </span>
            <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
          </span>
        </b-alert>
      </template>
      <template #cell(author)="{item}">
        {{item.active_history ? item.active_history.author.lastname : ''}}
        {{item.active_history ? item.active_history.author.firstname : ''}}
      </template>
      <template #cell(action)="{item}">
        <b-button size="sm" variant="primary" @click="handlePageChange(item)" class="mr-1">
          <b-icon icon="eye"></b-icon>
        </b-button>
      </template>

    </b-table>
  </b-card>
</div>
  </section>
</template>

<script>

export default {
  name: 'SecondUserTable',
  props: {
    documents: {
      default() {
        return []
      },
      type: Array,
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'paragraph',
          label: this.$t('message.Paragraph'),
        },
        {
          key: 'deadline',
          label: this.$t('Deadline'),
        },
        {
          key: 'author',
          label: this.$t('Author'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  computed: {
    loading() {
      return this.$store.state.settings.loading
    }
  },
  methods: {
    handleEdit(id) {
      this.$router.push({ name: 'home', params: { id } })
    },
    handlePageChange(data) {
      this.$router.push({
        name: 'paragraphHistory',
        params: { id: data.document_id, paragraphId: data.id },
      })
    }
  }
}
</script>

<style scoped>

</style>
